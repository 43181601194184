var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "page-user-view" } },
    [
      _c(
        "vs-alert",
        {
          attrs: {
            color: "danger",
            title: "User Not Found",
            active: _vm.user_not_found,
          },
          on: {
            "update:active": function ($event) {
              _vm.user_not_found = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "User record with id: " +
                _vm._s(_vm.$route.params.userId) +
                " not found. "
            ),
          ]),
          _c(
            "span",
            [
              _c("span", [_vm._v("Check ")]),
              _c(
                "router-link",
                {
                  staticClass: "text-inherit underline",
                  attrs: { to: { name: "page-user-list" } },
                },
                [_vm._v("All Users")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.user_data
        ? _c(
            "div",
            { attrs: { id: "user-data" } },
            [
              _c(
                "vx-card",
                { staticClass: "mb-base", attrs: { title: "Account" } },
                [
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col", attrs: { id: "avatar-col" } },
                      [
                        _c("div", { staticClass: "img-container mb-4" }, [
                          _c("img", {
                            staticClass: "rounded w-full",
                            attrs: { src: _vm.user_data.avatar },
                          }),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "vx-col flex-1",
                        attrs: { id: "account-info-col-1" },
                      },
                      [
                        _c("table", [
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Username"),
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.user_data.username))]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Name"),
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.user_data.name))]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Email"),
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.user_data.email))]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "vx-col flex-1",
                        attrs: { id: "account-info-col-2" },
                      },
                      [
                        _c("table", [
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Status"),
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.user_data.status))]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Role"),
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.user_data.role))]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Company"),
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.user_data.company))]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "vx-col w-full flex",
                        attrs: { id: "account-manage-buttons" },
                      },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "mr-4",
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-edit",
                              to: {
                                name: "app-user-edit",
                                params: { userId: _vm.$route.params.userId },
                              },
                            },
                          },
                          [_vm._v("Edit")]
                        ),
                        _c(
                          "vs-button",
                          {
                            attrs: {
                              type: "border",
                              color: "danger",
                              "icon-pack": "feather",
                              icon: "icon-trash",
                            },
                            on: { click: _vm.confirmDeleteRecord },
                          },
                          [_vm._v("Delete")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col lg:w-1/2 w-full" },
                  [
                    _c(
                      "vx-card",
                      {
                        staticClass: "mb-base",
                        attrs: { title: "Information" },
                      },
                      [
                        _c("table", [
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Birth Date"),
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.user_data.dob))]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Mobile"),
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.user_data.mobile))]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Website"),
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.user_data.website))]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Languages"),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.user_data.languages_known.join(", "))
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Gender"),
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.user_data.gender))]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Contact"),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.user_data.contact_options.join(", "))
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col lg:w-1/2 w-full" },
                  [
                    _c(
                      "vx-card",
                      {
                        staticClass: "mb-base",
                        attrs: { title: "Social Links" },
                      },
                      [
                        _c("table", [
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Twitter"),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.user_data.social_links.twitter)
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Facebook"),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.user_data.social_links.facebook)
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Instagram"),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.user_data.social_links.instagram)
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Github"),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.user_data.social_links.github)),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("CodePen"),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.user_data.social_links.codepen)
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { staticClass: "font-semibold" }, [
                              _vm._v("Slack"),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.user_data.social_links.slack)),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("vx-card", [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-end px-3" },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-2",
                            attrs: { svgClasses: "w-6 h-6", icon: "LockIcon" },
                          }),
                          _c(
                            "span",
                            { staticClass: "font-medium text-lg leading-none" },
                            [_vm._v("Permissions")]
                          ),
                        ],
                        1
                      ),
                      _c("vs-divider"),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "block overflow-x-auto" }, [
                  _c(
                    "table",
                    { staticClass: "w-full permissions-table" },
                    [
                      _c(
                        "tr",
                        _vm._l(
                          ["Module", "Read", "Write", "Create", "Delete"],
                          function (heading) {
                            return _c(
                              "th",
                              {
                                key: heading,
                                staticClass:
                                  "font-semibold text-base text-left px-3 py-2",
                              },
                              [_vm._v(_vm._s(heading))]
                            )
                          }
                        ),
                        0
                      ),
                      _vm._l(_vm.user_data.permissions, function (val, name) {
                        return _c(
                          "tr",
                          { key: name },
                          [
                            _c("td", { staticClass: "px-3 py-2" }, [
                              _vm._v(_vm._s(name)),
                            ]),
                            _vm._l(val, function (permission, name) {
                              return _c(
                                "td",
                                {
                                  key: name + permission,
                                  staticClass: "px-3 py-2",
                                },
                                [
                                  _c("vs-checkbox", {
                                    staticClass: "pointer-events-none",
                                    model: {
                                      value: val[name],
                                      callback: function ($$v) {
                                        _vm.$set(val, name, $$v)
                                      },
                                      expression: "val[name]",
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }